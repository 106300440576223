<template>
  <div class="main-page">
    <div class="d-flex sc-width main-content just-content-between">
      <div class="left">
        <div class="left-top">
          <div class="logo">
            <img src="@/assets/new-company/company-logo.png" alt="" />
          </div>
          <div class="infos">
            <div class="info-item">
              <div class="item-logo left-logo-1">
                <img src="@/assets/new-company/president.png" alt="" />
              </div>
              <div class="item-infos">
                <div class="item-label">President</div>
                <div class="item-val">232</div>
              </div>
            </div>
            <div class="info-item">
              <div class="item-logo left-logo-2">
                <img src="@/assets/new-company/Executwes.png" alt="" />
              </div>
              <div class="item-infos">
                <div class="item-label">Executwes</div>
                <div class="item-val">15</div>
              </div>
            </div>
            <div class="info-item">
              <div class="item-logo left-logo-3">
                <img src="@/assets/new-company/sales.png" alt="" />
              </div>
              <div class="item-infos">
                <div class="item-label">Sales</div>
                <div class="item-val">200</div>
              </div>
            </div>
            <div class="info-item">
              <div class="item-logo left-logo-4">
                <img src="@/assets/new-company/Employes.png" alt="" />
              </div>
              <div class="item-infos">
                <div class="item-label">Employes</div>
                <div class="item-val">2300</div>
              </div>
            </div>
            <div class="info-item">
              <div class="item-logo left-logo-5">
                <img src="@/assets/new-company/Factories.png" alt="" />
              </div>
              <div class="item-infos">
                <div class="item-label">Factories</div>
                <div class="item-val">10</div>
              </div>
            </div>
            <div class="info-item">
              <div class="item-logo left-logo-6">
                <img src="@/assets/new-company/Products.png" alt="" />
              </div>
              <div class="item-infos">
                <div class="item-label">Products</div>
                <div class="item-val">50</div>
              </div>
            </div>
            <div class="info-item">
              <div class="item-logo left-logo-1">
                <img src="@/assets/new-company/HQ.png" alt="" />
              </div>
              <div class="item-infos">
                <div class="item-label">HQ</div>
                <div class="item-val">United States</div>
              </div>
            </div>
            <div class="info-item">
              <div class="item-logo left-logo-2">
                <img src="@/assets/new-company/Association.png" alt="" />
              </div>
              <div class="item-infos">
                <div class="item-label">Association</div>
                <div class="item-val">Ingredients</div>
              </div>
            </div>
          </div>
        </div>


        <div class="create">
          <div class="create-logo">
            <img src="@/assets/new-company/create.png" alt="" />
          </div>
          <div class="create-name">
            Create Your Storefront
          </div>
          <div class="create-btn">
            <img src="@/assets/new-company/arrow.png" alt="" />
          </div>
        </div>
      </div>
      <div class="mid">
        <div class="company-name">DSM COMPANY</div>
        <div class="company-detail ">
          <div class="left-detail">
            <div class="detail-item" :class="{'detail-item-active':companyActiveItem == 1}" @click="updateCompanyActive(1)">
              <div class="detail-item-icon">
                <!--              <img src="@/assets/new-company/Organization.png" alt="" />-->
              </div>
              <div class="detail-item-value">Organization</div>
            </div>
            <div class="detail-item" :class="{'detail-item-active':companyActiveItem == 2}" @click="updateCompanyActive(2)">
              <div class="detail-item-icon">
                <!--              <img src="@/assets/new-company/Distribution.png" alt="" />-->
              </div>
              <div class="detail-item-value">Distribution</div>
            </div>
<!--            <div class="detail-item" :class="{'detail-item-active':companyActiveItem == 3}" @click="updateCompanyActive(3)">-->
<!--              <div class="detail-item-icon">-->
<!--                &lt;!&ndash;              <img src="@/assets/new-company/earth.png" alt="" />&ndash;&gt;-->
<!--              </div>-->
<!--              <div class="detail-item-value">3D Earth</div>-->
<!--            </div>-->
<!--            <div class="detail-item" :class="{'detail-item-active':companyActiveItem == 4}" @click="updateCompanyActive(4)">-->
<!--              <div class="detail-item-icon">-->
<!--                &lt;!&ndash;              <img src="@/assets/new-company/demo.png" alt="" />&ndash;&gt;-->
<!--              </div>-->
<!--              <div class="detail-item-value">Demo</div>-->
<!--            </div>-->
<!--            <div class="detail-item">-->
<!--              <div class="detail-item-icon">-->
<!--                &lt;!&ndash;              <img src="@/assets/new-company/news.png" alt="" />&ndash;&gt;-->
<!--              </div>-->
<!--              <div class="detail-item-value">News</div>-->
<!--            </div>-->
          </div>
          <div class="right-detail">
            <div class="detail-organization" v-if="companyActiveItem == 1">

              <vue2-org-tree
                :data="treeData"
                collapsable
                :horizontal=false
                :render-content="renderContent"
                @on-expand="onExpand"
                @on-node-click="NodeClick"
              />
            </div>
            <div class="detail-distribution" v-if="companyActiveItem == 2">
              <l-map
                v-if="showMap"
                :zoom="zoom"
                :center="center"
                :options="mapOptions"
                style="height: 80%"
                @update:center="centerUpdate"
                @update:zoom="zoomUpdate"
              >
                <l-tile-layer
                  :url="url"
                  :attribution="attribution"
                />
                <l-marker :lat-lng="withPopup1" :icon="icon">

                </l-marker>
                <l-marker :lat-lng="withPopup2" :icon="icon">

                </l-marker>

              </l-map>
            </div>
            <div class="3d-earth" v-show="companyActiveItem == 3">
              <canvas
                id="cobe"
                style="width: 450px; height: 450px"
                width="1000"
                height="1000"
              ></canvas>

            </div>
          </div>
        </div>
        <div class="factories">
          <div class="factory-top">
            <div class="factory-top-name d-flex">
              <div class="factory-top-name-item factory-top-name-item-active">Factories</div>
              <div class="factory-top-name-item"> | </div>
              <div class="factory-top-name-item">Offices</div>
            </div>
            <div class="factory-top-filter">
              <div class="factory-top-filter-item">Industries</div>
              <div class="factory-top-filter-item">Category</div>
            </div>
          </div>
          <div class="factory-detail">
            <div class="factory-item">
              <div class="factory-info">
                <div class="country"></div>
                <div class="name">test factory</div>
                <div class="address">factory address</div>
                <div class="arrow">-></div>
              </div>

              <div class="avatar">
                <img src="@/assets/new-company/factory-avatar.png" alt="" />
              </div>

            </div>
            <div class="factory-item">
              <div class="factory-info">
                <div class="country"></div>
                <div class="name">test factory</div>
                <div class="address">factory address</div>
                <div class="arrow">-></div>
              </div>

              <div class="avatar">
                <img src="@/assets/new-company/factory-avatar.png" alt="" />
              </div>

            </div>
            <div class="factory-item">
              <div class="factory-info">
                <div class="country"></div>
                <div class="name">test factory</div>
                <div class="address">factory address</div>
                <div class="arrow">-></div>
              </div>

              <div class="avatar">
                <img src="@/assets/new-company/factory-avatar.png" alt="" />
              </div>

            </div>

            <div class="factory-item">
              <div class="factory-info">
                <div class="country"></div>
                <div class="name">test factory</div>
                <div class="address">factory address</div>
                <div class="arrow">-></div>
              </div>

              <div class="avatar">
                <img src="@/assets/new-company/factory-avatar.png" alt="" />
              </div>

            </div>
            <div class="factory-item">
              <div class="factory-info">
                <div class="country"></div>
                <div class="name">test factory</div>
                <div class="address">factory address</div>
                <div class="arrow">-></div>
              </div>

              <div class="avatar">
                <img src="@/assets/new-company/factory-avatar.png" alt="" />
              </div>

            </div>
            <div class="factory-item">
              <div class="factory-info">
                <div class="country"></div>
                <div class="name">test factory</div>
                <div class="address">factory address</div>
                <div class="arrow">-></div>
              </div>

              <div class="avatar">
                <img src="@/assets/new-company/factory-avatar.png" alt="" />
              </div>

            </div>
          </div>
        </div>

      </div>
      <div class="right">
        <div class="country">
          <div class="country-label">Country/Region</div>
          <div class="country-value">
            <select class="country-select">
             <option>United States</option>
             <option>India</option>
          </select>
          </div>
        </div>

        <div class="quick">
          <div class="quick-label">Quick Menus</div>
          <div class="quick-items">
            <div class="quick-items-line">
              <div class="quick-item">
                <div class="quick-items-line-c">
                  <img src="@/assets/new-company/menu-1.png" alt="" />
                  <div :class="['quick-text', { 'quick-text-active': 'menuOverNum' == 1 }]" @mouseover="menuOver(1)">
                    Menu
                  </div>
                </div>
              </div>
              <div class="quick-item">
                <div class="quick-items-line-c">
                  <img src="@/assets/new-company/menu-2.png" alt="" />
                  <div :class="['quick-text', { 'quick-text-active': menuOverNum == 2 }]" @mouseover="menuOver(2)" @mouseleave="menuLeave()">
                    Menu
                  </div>
                </div>
              </div>
              <div class="quick-item" :class="{ 'quick-item-active': menuOverNum == 3 }" @mouseover="menuOver(3)" @mouseleave="menuLeave()">
                <div class="quick-items-line-c">
                  <img src="@/assets/new-company/menu-3.png" alt="" />
                  <div class="quick-text" >
                    Menu
                  </div>
                </div>

              </div>
            </div>

            <div class="quick-items-line quick-items-line-bottom">
              <div class="quick-item" :class="{ 'quick-item-active': menuOverNum == 4 }" @mouseover="menuOver(4)" @mouseleave="menuLeave()">
                <div class="quick-items-line-c">
                  <img src="@/assets/new-company/menu-4.png" alt="" />
                  <div class="quick-text">
                    Menu
                  </div>
                </div>
              </div>
              <div class="quick-item" :class="{ 'quick-item-active': menuOverNum == 5 }" @mouseover="menuOver(5)" @mouseleave="menuLeave()">
                <div class="quick-items-line-c">
                  <img src="@/assets/new-company/menu-5.png" alt="" />
                  <div class="quick-text">
                    Menu
                  </div>
                </div>
              </div>
              <div class="quick-item" :class="{ 'quick-item-active': menuOverNum == 6 }" @mouseover="menuOver(6)" @mouseleave="menuLeave()">
                <div class="quick-items-line-c">
                  <img src="@/assets/new-company/menu-6.png" alt="" />
                  <div class="quick-text">
                    Menu
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>

        <div class="social">
          <div class="social-title">Social Media</div>
          <div class="social-items d-flex">
            <div class="social-item">
              <img src="@/assets/new-company/website.png" alt="" />
            </div>
            <div class="social-item">
              <img src="@/assets/new-company/linkedin.png" alt="" />
            </div>
            <div class="social-item">
              <img src="@/assets/new-company/youtube.png" alt="" />
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>


</template>

<script>
  import { latLng,icon } from "leaflet";
  import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from "vue2-leaflet";
  import 'leaflet/dist/leaflet.css';
  // import createGlobe from 'cobe';



  export default {
  computed: {},
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip
  },
  data() {
    return {
      mobileWidth: window.innerWidth < this.$mobileWidth ,
      padWidth: window.innerWidth >= this.$mobileWidth && window.innerWidth <= this.$padWidth,
      currentWidth: window.innerWidth,
      menuOverNum : 0,
      companyActiveItem: 1,
      zoom: 1,
      center: latLng(47.41322, -1.219482),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '',
      withPopup1: latLng(37.7595, -122.4367),
      withPopup2: latLng(40.7128, -74.006),
      withTooltip: latLng(47.41422, -1.250482),
      currentZoom: 11.5,
      currentCenter: latLng(47.41322, -1.219482),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
      },
      showMap: true,
      icon: icon({
        iconUrl: require("@/assets/new-company/location.png"),
        iconSize: [32, 37],
        iconAnchor: [16, 37]
      }),
      labelClassName: "bg-color-orange",
      basicInfo: { id: null, label: null },
      basicSwitch: false,
      treeData: {
        id: '0',
        label: "DSM COMPANY",
        children: [
          {
            id: '1',
            label: "Sales",
            children: [
              {
                id: '1-1',
                label: "Sale1"
              },
              {
                id: '1-2',
                label: "Sale2"
              },
              {
                id: '1-3',
                label: "Sale3"
              },
              {
                id: '1-4',
                label: "Sale4",
                children: [
                  {
                    id: '2-2',
                    label: "Sale2222"
                  },
                  {
                    id: '2-3',
                    label: "Sale3333"
                  },
                ]
              }
            ]
          },
          {
            id: '2',
            label: "IT",
            children: [
              {
                id: '2-1',
                label: "IT1"
              },
              {
                id: '2-2',
                label: "IT2"
              },
              {
                id: '2-3',
                label: "IT2"
              },
              {
                id: '2-4',
                label: "IT3"
              }
            ]
          },
          {
            id: '3',
            label: "Sales-3",
            children: [
              {
                id: '3-1',
                label: "Sale1"
              },
              {
                id: '3-2',
                label: "Sale2"
              },
              {
                id: '3-3',
                label: "Sale3",
                children: [
                  {
                    id: '2-2',
                    label: "Sale2222"
                  },
                  {
                    id: '2-3',
                    label: "Sale3333"
                  },
                ]
              },
              {
                id: '3-4',
                label: "Sale4"
              }
            ]
          },
        ]
      }

    };
  },
  created() {
    this.toggleExpand(this.treeData,true);
  },
  mounted() {
    window.addEventListener('resize',() => this.changeWidth(), false);
    // this.$nextTick(() => {
    //   let phi = 0
    //   let canvas = document.getElementById("cobe")
    //   const globe = createGlobe(canvas, {
    //     devicePixelRatio: 2,
    //     width: 1000,
    //     height: 1000,
    //     phi: 0,
    //     theta: 0,
    //     dark: 0,
    //     diffuse: 1.2,
    //     scale: 1,
    //     mapSamples: 16000,
    //     mapBrightness: 6,
    //     baseColor: [1, 1, 1],
    //     markerColor: [1, 0.5, 1],
    //     glowColor: [1, 1, 1],
    //     offset: [0, 0],
    //     markers: [
    //       { location: [37.7595, -122.4367], size: 0.1 },
    //       { location: [40.7128, -74.006], size: 0.1 },
    //     ],
    //     onRender: (state) => {
    //       // Called on every animation frame.
    //       // `state` will be an empty object, return updated params.
    //       state.phi = phi
    //       phi += 0.01
    //     },
    //   })
    // });

  },
  watch: {
    currentWidth (val) {
      this.currentWidth = val
      this.mobileWidth = this.currentWidth <= this.$mobileWidth
      this.padWidth = this.currentWidth > this.$mobileWidth && this.currentWidth <= this.$padWidth;
    }
  },
  methods: {
    collapse(list) {
      list.forEach((child)=> {
        if (child.expand) {
          child.expand = false;
        }
        child.children && this.collapse(child.children);
      });
    },
    onExpand(e,data) {
      console.log(data,'data')
      if ("expand" in data) {
        data.expand = !data.expand;
        if (!data.expand && data.children) {
          this.collapse(data.children);
        }
      } else {
        this.$set(data, "expand", true);
      }
    },
    toggleExpand(data, val) {
      if (Array.isArray(data)) {
        data.forEach((item)=> {
          this.$set(item, "expand", val);
          if (item.children) {
            this.toggleExpand(item.children, val);
          }
        });
      } else {
        this.$set(data, "expand", val);
        if (data.children) {
          this.toggleExpand(data.children, val);
        }
      }
    },
    NodeClick(e,data){
      console.log(e)
      // e 为 event
      console.log(data)
      // 当前项的所有详情 如：id label children
    },
    renderContent(h, data) {
      return (
        <div class="treeItem">{data.label}</div>
    )
    },
    updateCompanyActive(num){
      this.companyActiveItem = num;
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick() {
      alert("Click!");
    },
    menuOver(num){
      this.menuOverNum = num;
    },
    menuLeave(){
      this.menuOverNum = 0;
    },
    changeWidth(){
      this.currentWidth = document.documentElement.clientWidth
    }
  },
  filters: {},
  beforeDestroy () {
    window.removeEventListener('resize', this.changeWidth(), false)
  }
};
</script>
<style lang="less" >
  @import '../../org-tree.less';

</style>
<style lang="less" scoped>

  .org-tree-node-label-inner {
    color: #fff;
    background-color: orange;
  }
  .main-content{
    margin-top: 80px;
    border-radius: 20px;
    margin: 80px auto 0 auto;
    background: #fff;
  }
  .d-flex{
    display: flex;
  }
  .just-content-between{
    display: flex;
    justify-content: space-between;
  }
  .left{
    padding: 20px 15px 20px 40px;
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .left-top{
      margin-top: 30px;
      .logo{
        margin-bottom: 40px;

      }
    }
    .info-item{
      display: flex;
      justify-content: start;
      margin: 18px auto;
      /*width: 140px;*/
    }
    .item-logo{
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      width: 37px;
      height: 37px;
      margin-right: 20px;
      margin-top: 3px;
      img{
        width: 22px;
        height: 22px;
      }


    }
    .item-infos{
      .item-val{
        font-weight: 600;
        font-size: 15px;
      }
      .item-label{
        font-size: 13px;
        font-weight: 500;
      }
    }
    .left-logo-1{
      background: #887CEC;
    }
    .left-logo-2{
      background: #61C5FF;
    }
    .left-logo-3{
      background: #FF9155;
    }
    .left-logo-4{
      background: #FFF420;
    }
    .left-logo-5{
      background: #695DFB;
    }
    .left-logo-6{
      background: #FA747B;
    }
    .create{
      margin-bottom: 50px;
      .create-logo{
        img{
          width: 100%;
        }
      }
      .create-name{
        font-size: 15px;
        font-weight: 700;
        margin: 0px auto;
      }
      .create-btn{
        border-radius: 50%;
        width: 40px;
        height: 40px;
        background: #FF4835;
        margin: 10px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img{
          width: 23px;
          height: 23px;
        }
      }
    }
  }
  .mid{
    width: 1050px;
    margin: 30px auto;
    border-radius: 25px;
    .company-name{
      color: #46485F;
      font-weight: 700;
      font-size: 38px;
      margin: 30px 30px 20px 30px;
    }
    background: #F3F5FE;
    .company-detail{
      border-radius: 20px;
      border: 1px solid #E2E2E2;
      margin: 30px;
      padding: 20px 30px;
      justify-content: space-between;
      .left-detail{
        display: flex;
        .detail-item{
          cursor: pointer;
          display: flex;
          justify-content: start;
          margin: 6px 0;
          padding: 3px 10px;
          height: fit-content;
          width: fit-content;
          .detail-item-value{
            font-size: 15px;
            font-weight: 500;
            color: #46485F;
          }
        }
        .detail-item-active{
          /*background: #FF4835;*/
          /*padding: 6px 15px;*/
          border-radius: 5px;
        }
        .detail-item-active{
          .detail-item-icon{

          }
          .detail-item-value{
            color: #887CEC;
            font-weight: 600;

          }
        }
      }
      .right-detail{
        width: 100%;
        margin-top: 20px;

        .detail-organization{
          padding-bottom: 15px;
          overflow: auto;

        }
        .detail-distribution{
          height: 400px;
        }
      }
    }
  }
  .right{
    width: 300px;
    padding: 30px;
  }
  .factories{
    margin: 30px;
    .factory-top-name{
      font-size: 15px;
      font-weight: 600;
      .factory-top-name-item{
        margin: 0 3px;
        cursor: pointer;
        color: #9799B2;
      }
      .factory-top-name-item-active{
        color: #887CEC;
      }
    }
  }
  .factory-detail{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .factory-item{
      height: 190px;
      width: 190px;
      cursor: pointer;
      position: relative;
    }
    .factory-item:hover::after {
      content: '';
      background-color: rgba(255, 255, 255, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      width: 190px;
      height: 190px;
      z-index: 99;
    }
    .factory-info{
      position: relative;
      z-index: 99;
    }
    .avatar{
      position: relative;
      bottom: 80px;
      z-index: 1;
    }

  }
  .products{
    margin: 30px;
    .product-top-name{
      font-size: 15px;
      font-weight: 600;
      .product-top-name-item{
        margin: 0 3px;
        cursor: pointer;
        color: #9799B2;
      }
      .product-top-name-item-active{
        color: #887CEC;
      }
    }
  }
  .product-detail{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .product-item{
      height: 190px;
    }
    .product-info{
      position: relative;
      z-index: 99;
    }
    .avatar{
      position: relative;
      bottom: 80px;
      z-index: 1;
    }

  }
  .country{
    .country-label{
      color: #9799B2;
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .country-select{
      border: 2px solid #887CEC;
      height: 40px;
      border-radius: 10px;
      width: 180px;
      padding: 3px 10px;
      color: #887CEC;
      font-size: 15px;
      font-weight: 500;
    }
  }
  .quick{
    margin-top: 30px;
  }
  .quick-label{
    color: #9799B2;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .quick-items{

    .quick-items-line{
      justify-content: space-between;
      flex-wrap: wrap;
      display: flex;
      :hover {
        transform: scale(1.4,1.4);
        z-index: 99;
      }
      .quick-items-line-c{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }

    }
    .quick-items-line-bottom{
      margin-top: 10px;
    }

    .quick-item{
      /*padding: 12px;*/
      width: 70px;
      height: 70px;
      border-radius: 15px;
      /*border: 1px solid #c1c1c1;*/
      box-shadow: 1px 1px 4px 2px rgba(136, 124, 236, 0.25);

      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      :hover {
        transform: scale(1);
      }
      img{
        width: 33px;
        :hover {
          transform: scale(1);
        }
      }
      transition: transform 0.2s ease;
      background: #fff;
      .quick-text{
        display: none;
        color: #887CEC;
        font-size: 13px;
        font-weight: 600;
        :hover {
          transform: scale(1);
        }
      }

    }
    .quick-item-active{
      .quick-text{
        display: block;
      }
    }

  }
  #cobe{
    position: relative;
    right: 145px;
  }
  .main-page{
    background: #f2f2f2;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .social{
    margin-top: 30px;
    .social-title{
      color: #9799B2;
      font-size: 15px;
      font-weight: 600;
    }
    .social-items{
      margin-top: 10px;
      display: flex;
      justify-content: start;
      .social-item{
        margin-right: 10px;
        img{
          height: 30px;
        }
      }

    }
  }
</style>
<style lang="less" >
  .vue2leaflet-map .leaflet-control-attribution{
    display: none;
  }
  </style>
